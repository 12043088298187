import React from 'react';
import Layout from '@common/Layout';
import NavbarSubpages from '@common/Navbar/NavbarSubpages';
import Download from '@sections/Download';
import Footer from '@sections/Footer';

const SecondPage = () => (
  <Layout>
    <NavbarSubpages />
    <Layout>
    <Download />
    </Layout>
    <Footer />
  </Layout>
);

export default SecondPage;