import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import AppleIcon from '@static/icons/apple.svg';
import WindowsIcon from '@static/icons/windows.svg';
import window from 'global';
import { GlobalLink } from '@components/Link';

const ICONS = [
   {
    icon: AppleIcon,
    link: 'https://rocketbatch.com/download?platform=apple',
  },
  {
    icon: WindowsIcon,
    link: 'https://rocketbatch.com/download?platform=windows',
  },
];


const DownloadGrid = styled.div`
display: grid;
width: 100%;
grid-template-columns: 700px 500px;
`;

const Bullet = styled.figure`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    line-height: 25px;
    text-align: center;
    background: #FE5A79;
    display:inline-block;
    position:relative;
`;

const BulletText = styled.figure`
    display:inline-block;
`;

const list = [
  {
    id: '1',
    stepname: 'Download rocketbatch',
  },
  {
    id: '2',
    stepname: 'Create your account',
  },
  {
    id: '3',
    stepname: 'Start organise ',
  },
];

const Art = styled.figure`
  width: 500px;
  margin: 0px 0;
 left:30%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 50%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;




  

const Download = () => (

  

  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_showcase: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "download" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (

      <Section id="download">
        <Container style={{ position: 'relative',height: '100%' }}>

        <div style={{ margin: `3rem auto`, maxWidth: 600 }}>
          <p>
            {getOsType()}
          </p>
          <h1>Thanks for downloading.</h1>
          <p>
          If your download doesn’t start automatically, please try again.
          </p>
        </div>

          <DownloadGrid>
              <Art>
                <Img fluid={data.art_showcase.childImageSharp.fluid} />
              </Art>

              <div>
                <h6>Get it now</h6>
                <h3>Installing rocketbatch</h3>
                <ul style={{listStyleType: "none", padding: "0px", marginLeft: "-40px"}}>
                  {list.map(item => (
                    <li key={item.id}>
                      <Bullet>{item.id}</Bullet>
                      <BulletText>{item.stepname}</BulletText>
                    </li>
                  ))}
                </ul>
                <h6>Having issues installing?</h6>

                

                <StyledExternalLink href="https://rocketbatch.com/docs/#/">
                    Visit the Docs &nbsp;&#x2794;
                </StyledExternalLink>
                
              </div>
          </DownloadGrid>

        </Container>
      </Section>
    )}
  />
);





var downloadFile = function (sUrl) {

  

  if (typeof window.navigator.userAgent === "undefined") {
    return;
  }

  //iOS devices do not support downloading. We have to inform user about this.
  if (/(iP)/g.test(window.navigator.userAgent)) {
      alert('Your device does not support files downloading. Please try again in desktop browser.');
      return false;
  }

  let isChrome = window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  let isSafari = window.navigator.userAgent.toLowerCase().indexOf('safari') > -1;

  //If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
      
     //Creating new link node.
      var link = document.createElement('a');
      link.href = sUrl;

      if (link.download !== undefined) {
          //Set HTML5 download attribute. This will prevent file from opening if supported.
          var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
          link.download = fileName;
      }

      //Dispatching click event.
      if (document.createEvent) {
          var e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          link.dispatchEvent(e);
          return true;
      }
  }

  // Force file download (whether supported by server).
  if (sUrl.indexOf('?') === -1) {
      sUrl += '?download';
  }

  window.open(sUrl, '_self');
  return true;
}

var getOsType = function() {
 
  let osName = "Unknown";
  let iIndex = 3;

  if (typeof window.navigator === "undefined") {
    return;
  }

  if (window.navigator.userAgent.indexOf("Win") !== -1) {
    osName = "windows";
    iIndex = 1;
    downloadFile("https://rocketbatch.com/rocketbatch.exe");
  }
  if (window.navigator.userAgent.indexOf("Mac") !== -1) {
    osName = "apple";
    downloadFile("https://rocketbatch.com/rocketbatch.dmg");
    iIndex = 0;
  }
  /*
  if (window.navigator.userAgent.indexOf("Linux") != -1) {
    osName = "Linux";
  }
  if (window.navigator.userAgent.indexOf("Android") != -1) {
    osName = "Android";
  }
  if (window.navigator.userAgent.indexOf("like Mac") != -1) {
    osName = "iOS";
  }
  */

  var osTypeInformation = {
    os: osName,
    index: iIndex
  }
  
  
  
  return (<h5>rocketbatch for {osTypeInformation.os} <img src={ICONS[osTypeInformation.index].icon} alt="link" width="16px" style={{paddingTop:'4px'}}/></h5> );
};

const StyledExternalLink = styled.a`
  color: #0056E5;
  text-decoration: none;
  font-size: 20px;
    line-height: 30px;
  &:hover {
    color: ${props => props.theme.color.link};
  }
`;

export default Download;


